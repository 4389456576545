import React, { useEffect } from 'react';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { setNonjoinableModal } from '@artemis/store/groupOrder/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFulfillmentState,
  getIsNonjoinableModalOpen,
  getJoinError,
} from '@artemis/store/groupOrder/selectors';
import { FULFILLMENT_STATE } from '@artemis/utils/constants';
import StandardPrompt from '@artemis/components/StandardPrompt';
import { clearInvitation } from '@artemis/store/invite/slice';
import { useRemoveQueryParameter } from '@artemis/utils/query';
import { INVITE_LINK_ID_PARAM } from '@artemis/utils/query/constants';

const GroupOrderNonjoinableModal = () => {
  const dispatch = useDispatch();
  const removeQueryParameter = useRemoveQueryParameter();

  const isOpen = useSelector(getIsNonjoinableModalOpen);
  const fulfillmentState = useSelector(getFulfillmentState);
  const joinError = useSelector(getJoinError);

  const titleKey =
    // 400 indicates the group order has no available spots
    joinError?.response?.status === 400
      ? 'groupOrder.nonjoinableModal.noSpotsTitle'
      : 'groupOrder.nonjoinableModal.title';

  const onClose = () => {
    dispatch(clearInvitation());
    removeQueryParameter(INVITE_LINK_ID_PARAM);
    dispatch(setNonjoinableModal({ isOpen: false }));
  };

  useEffect(() => {
    if (fulfillmentState === FULFILLMENT_STATE.CLOSED) {
      dispatch(setNonjoinableModal({ isOpen: true }));
    }
  }, [fulfillmentState]);

  return (
    <StandardPrompt
      primaryButtonText={<FormattedMessage entry="global.okay" />}
      title={<FormattedMessage entry={titleKey} />}
      onClose={onClose}
      isOpen={isOpen}
      onClickPrimaryButton={onClose}
      isClosable={false}
      size="sm"
    />
  );
};

export default GroupOrderNonjoinableModal;
